import React, { useEffect } from 'react'
import { foodFormTabs } from '../../../../../data/foodFormTabs'
import CustomTextArea from '../../../../Custom/CustomTextArea'

const Description = ({initialValues, foodType, register, setValue}) => {

  useEffect(() => {
    if (!foodFormTabs.find(item => item.id === foodType)?.tabs?.some(tab => tab.value === 'description')) {
      setValue('desc', null);
    } else if  (initialValues?.type_id === foodType) {
      setValue('desc', initialValues?.desc)
    } else {
      setValue("desc", "")
    }
  }, [foodType])

  return (
    <CustomTextArea 
      {...register('desc')}
    />
  )
}

export default Description